@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F2F2;
} 

.bg-custom {
  background-image: url('/src/assets/images/login.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.tradingview-widget-copyright {
  display: none;
}

@keyframes slow-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.animate-slow-spin {
    animation: slow-spin 15s linear infinite;
}

.bg-gradient-conic {
    background-image: conic-gradient(var(--tw-gradient-stops));
}

@keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}

.delay-75 {
    animation-delay: 75ms;
}

.delay-150 {
    animation-delay: 150ms;
}
